.EditorialWysiwyg {
  picture,
  iframe,
  audio,
  video,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  table,
  ul,
  ol {
    @apply mb-xl;
  }

  > *:last-child {
    @apply mb-none;
  }

  h2 {
    @apply typeface-heading-a5-special;
  }

  h3 {
    @apply typeface-heading-a6-special;
  }

  /* stylelint-disable */
  figure.table {
    @apply overflow-x-auto block;
  }

  table {
    @apply typeface-subtitle1-standard w-full table-fixed border-spacing-none border-separate border-grey-x-light border-[1px] rounded-xl overflow-hidden min-w-[770px];

    figcaption,
    caption {
      @apply typeface-heading-a5-special mb-xl;
    }

    thead {
      th {
        @apply typeface-subtitle1-special bg-grey-x-light;

        &:first-child {
          @apply rounded-tl-xl;
        }

        &:last-child {
          @apply rounded-tr-xl;
        }
      }
    }

    tfoot {
      td {
        @apply typeface-subtitle1-special text-brand-primary;
      }
    }

    tbody,
    thead,
    tfoot {
      @apply w-full;
    }

    tbody {
      td,
      th {
        @apply border-grey-x-light border-b-[1px];
      }
    }

    th {
      @apply text-left;
    }

    th,
    td {
      @apply p-lg break-words align-middle;
    }
  }
  /* stylelint-enable */

  p,
  ul,
  ol {
    @apply typeface-body1-standard;
  }

  /* stylelint-disable */
  ul {
    @apply mb-xl ml-[1.1em] list-disc;

    li {
      @apply mb-md;
    }

    ul,
    ol {
      @apply mt-md;

      &:last-child {
        @apply mb-none;
      }
    }
  }

  ol {
    @apply ml-[2em] list-[decimal-leading-zero] mb-xl;

    li {
      @apply mb-md;
    }

    ul,
    ol {
      @apply mt-md mb-none;

      &:last-child {
        @apply mb-none;
      }
    }
  }
  /* stylelint-enable */

  em {
    @apply italic;
  }

  a {
    @apply text-brand-primary transition-all border-b-xs border-brand-primary hover:text-brand-secondary hover:border-brand-secondary;
  }

  blockquote {
    @apply text-center my-xl text-brand-primary before:content-[''] before:w-[48px] before:h-[3px] before:mr-auto before:ml-auto before:mb-xl before:block before:bg-brand-primary;

    p {
      @apply typeface-heading-a5-standard after:content-['“'] before:content-['”'] mb-none;
    }
  }

  iframe {
    @apply w-full h-[420px];
  }

  audio {
    @apply min-w-[50%];
  }

  video {
    @apply min-w-[75%];
  }

  picture,
  iframe,
  audio,
  video {
    @apply max-w-full mx-auto block rounded-2xl overflow-hidden;
  }

  img {
    @apply rounded-2xl overflow-hidden;
  }

  figure {
    @apply my-4xl;

    img {
      @apply rounded-2xl overflow-hidden;
    }

    figcaption {
      @apply typeface-caption-special text-grey-dark mt-md;
    }
  }
}
